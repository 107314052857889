"use strict";
module.exports = {
    "common": {
        "environment": {
            "type": "production"
        },
        "insights": {
            "googleAnalytics": {
                "cookieDomain": "portal.klarna.com",
                "tagId": "G-RL0BMD5BRF"
            }
        },
        "keycloak": {
            "baseUrls": {
                "ap": "https://auth.oc.portal.klarna.com",
                "eu": "https://auth.eu.portal.klarna.com",
                "us": "https://auth.us.portal.klarna.com"
            }
        },
        "merchantPortalBaseUrl": "https://portal.klarna.com",
        "frontendEventRouterBaseUrls": {
            "eu": "https://eu.klarnaevt.com",
            "ap": "https://oc.klarnaevt.com",
            "us": "https://na.klarnaevt.com"
        },
        "nko": {
            "baseUrl": "https://merchants.klarna.com"
        },
        "sentry": {
            "enabled": true
        }
    }
};
