import { RegionalResponse, CountryCodeAndRegion, CountryCodeAndFeatureData, Market } from '../components/SupportHelpBot/types'

export const getCountryCodesAndRegions = (regionalResponses: RegionalResponse[]): CountryCodeAndRegion[] => (
  regionalResponses.map(
    mapCountryCodesAndRegion
  ).flat().filter(markets => markets)
)

export const getCountryCodes = (countryCodesAndRegions: CountryCodeAndRegion[]): string[] => (
  countryCodesAndRegions
    ? countryCodesAndRegions.map(
      countryCodeAndRegion => countryCodeAndRegion.countryCode
    )
    : []
)

export const getCountryCodesAndFeatureData = (regionalResponses: RegionalResponse[]): CountryCodeAndFeatureData[] => (
  regionalResponses.map(
    mapCountryCodesAndFeatureData
  ).flat()
    .filter(markets => markets)
)

export const mergeAndGetMarkets = (countryCodesAndRegions: CountryCodeAndRegion[], countryCodesAndFeatureData: CountryCodeAndFeatureData[]): Market[] => {
  if (!countryCodesAndRegions) return []
  const mergedMarkets: Market[] = []
  countryCodesAndRegions.forEach((value, index) => (
    mergedMarkets.push({
      isChatFeatureEnabled: false,
      ...countryCodesAndRegions[index],
      ...(countryCodesAndFeatureData?.find((innerIndex) => innerIndex.countryCode === countryCodesAndRegions[index].countryCode))
    })
  ))
  return mergedMarkets
}

const mapCountryCodesAndRegion = ({ region, response }: RegionalResponse): CountryCodeAndRegion[] => (
  response.data.map(
    (countryCode: string) => ({ region, countryCode })
  )
)

const mapCountryCodesAndFeatureData = ({ response }: RegionalResponse): CountryCodeAndFeatureData[] => (
  response.data.map(
    // eslint-disable-next-line camelcase
    ({ market, is_feature_enabled }) => ({ countryCode: market, isChatFeatureEnabled: is_feature_enabled })
  )
)
