import React from 'react'
import { hooks } from 'mage-i18n'
import { Chip } from '@klarna/bubble-ui'
import CountryFlag from '../../../Chat/CountryFlag'

interface MarketProps {
  countryCode: string
  onClick: () => void
}

interface Styles {
  container: Record<string, string>
}
const styles: Styles = {
  container: {
    marginBottom: '10px',
    textAlign: 'right'
  }
}

const MarketOption = ({ countryCode, onClick }: MarketProps) => {
  const t = hooks.useTranslator()
  const label = t(`core.countries.${countryCode.toLowerCase()}`)
  const FlagIcon = () => <CountryFlag countryCode={countryCode} />
  return (
    <div style={styles.container}>
      <Chip title={label} rightIcon={FlagIcon} onClick={onClick} />
    </div>
  )
}

export default MarketOption
