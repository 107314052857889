import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
import * as configSelectors from 'mage-config/src/selectors';
import coreActions from 'mage-core/src/actions';
import * as coreSelectors from 'mage-core/src/selectors';
import * as i18nSelectors from 'mage-i18n/src/selectors';
import realMoment from 'moment';
import * as R from 'ramda';
import * as selectors from '../selectors';
import * as utils from '../utils';
import clientActions from './mage-client';
import i18nActions from './mage-i18n';
/** THUNKS **/
const saveLanguage = (userId, language, { moment = realMoment, localStorage = window.localStorage } = {}) => (dispatch, getState) => {
    const state = getState();
    const client = coreSelectors.createBackendClient(state)('users');
    const locale = i18nSelectors.locale(state);
    // @ts-expect-error: FIXME
    dispatch(i18nActions.language.set(language));
    moment.locale(locale);
    try {
        localStorage.setItem('language', language);
    }
    catch (err) { /* empty */ }
    const env = configSelectors.environment(state);
    if (env === 'development') {
        document.cookie = `KEYCLOAK_LOCALE=${language}; path=/auth/realms/merchants/;`;
    }
    else {
        let domain = '.klarna.com';
        if (env === 'staging') {
            domain = '.klarna.net';
        }
        document.cookie = `KEYCLOAK_LOCALE=${language}; path=/auth/realms/merchants/; domain=${domain}`;
    }
    if (coreSelectors.isMerchantsRealm(state)) {
        return client.request({
            method: 'PUT',
            url: `/users/${userId}`,
            data: {
                language
            }
        })
            .catch(Sentry.captureException);
    }
    else {
        return Promise.resolve();
    }
};
const initialiseKeycloak = (options = {}) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const { keycloak = selectors.getKeycloak(getState()) } = options;
    // @ts-expect-error: NOTE - this is a property on the object; not sure if it's stable though
    if (!keycloak.didInitialize) {
        yield keycloak.init({
            checkLoginIframe: false,
            pkceMethod: 'S256'
        });
    }
    if (!keycloak.authenticated) {
        const loginOptions = {};
        const query = (_c = (_b = (_a = getState().router) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.query) !== null && _c !== void 0 ? _c : {};
        const sessionCookie = utils.getSessionCookie();
        const loginAcr = (_d = query.login_acr) !== null && _d !== void 0 ? _d : sessionCookie === null || sessionCookie === void 0 ? void 0 : sessionCookie.acr;
        const shouldLogInWithAcr = configSelectors.keycloak(getState()).stepUpAvailable;
        if (loginAcr && shouldLogInWithAcr) {
            loginOptions.acr = {
                values: [loginAcr],
                essential: true
            };
        }
        yield keycloak.login(loginOptions);
    }
    // @ts-expect-error: FIXME
    dispatch(coreActions.token.set(keycloak));
});
const refreshToken = (options = {}) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const { force = false, keycloak = selectors.getKeycloak(getState()), minValidity = selectors.keycloakConfig(getState()).refreshTokenThreshold, _Sentry = Sentry } = options;
    try {
        let tokenWasRefreshed;
        try {
            // NOTE: updateToken _requires_ an arg (ugh), so give it a day if forcing a refresh (assumes tokens expire in less time)
            tokenWasRefreshed = yield keycloak.updateToken(force ? 86400 : minValidity);
        }
        catch (wasError) { // NOTE: update token throws a boolean (rather than an actual Error object)
            throw new Error('Unable to update token');
        }
        if (tokenWasRefreshed) {
            if (keycloak.isTokenExpired(minValidity)) {
                throw new Error(`Token expiring in less than ${minValidity} seconds`);
            }
            // @ts-expect-error: FIXME
            dispatch(coreActions.token.set(keycloak));
        }
    }
    catch (err) {
        const { refreshTokenParsed: { exp: refreshTokenExp, iat: refreshTokenIat } = {}, tokenParsed: { exp: tokenExp, iat: tokenIat } = {} } = keycloak;
        _Sentry.captureException(err, {
            extra: {
                refreshTokenExp,
                refreshTokenIat,
                tokenExp,
                tokenIat
            }
        });
        dispatch(logout());
    }
});
const login = (options = {}) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const keycloak = selectors.getKeycloak(getState());
    yield keycloak.login(options);
    // @ts-expect-error: FIXME
    dispatch(coreActions.token.set(keycloak));
});
const logout = () => (dispatch, getState) => {
    const keycloak = selectors.getKeycloak(getState());
    const appBaseUrl = selectors.getAppBaseUrl(getState());
    utils.deleteSessionCookie();
    // @ts-expect-error: FIXME
    dispatch(coreActions.keycloak.realm.clear());
    if (keycloak.authenticated) {
        keycloak.logout({ redirectUri: appBaseUrl });
    }
    else {
        window.location.href = appBaseUrl;
    }
};
const fetch = (url, config = {}) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const getClient = () => /^(http|\/)/.test(url)
        ? coreSelectors.clientFactory(getState())()
        : coreSelectors.createClient(getState());
    try {
        return yield getClient().request(url, config);
    }
    catch (error) {
        if (R.pathEq(401, ['response', 'status'], error)) {
            // if 401 response, refresh the token and try again
            yield dispatch(refreshToken({ force: true }));
            return yield getClient().request(url, config);
        }
        else {
            throw error;
        }
    }
});
const fetchCapabilities = (options = {}) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    // @ts-expect-error: FIXME
    dispatch(clientActions.capabilities.loading());
    try {
        const response = yield dispatch(fetch('capabilities', options));
        // @ts-expect-error: FIXME
        dispatch(clientActions.capabilities.set(response));
    }
    catch (error) {
        // @ts-expect-error: FIXME
        dispatch(clientActions.capabilities.set(error));
    }
});
export { fetch, fetchCapabilities, initialiseKeycloak, login, logout, refreshToken, saveLanguage };
