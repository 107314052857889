import { selectors as experimentationSelectors } from '@merchant-portal/experimentation'
import { AuthenticationRequired } from 'mage-components'
import { bool, node } from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

import CenteredLoader from './CenteredLoader'

AuthenticationFork.propTypes = {
  requireAuthentication: bool,
  children: node
}

function AuthenticationFork ({ requireAuthentication, children }) {
  const isAuthenticatedFeaturesLoaded = useSelector(experimentationSelectors.isAuthenticatedFeaturesLoaded)

  if (requireAuthentication) {
    return (
      <AuthenticationRequired>
        {isAuthenticatedFeaturesLoaded ? children : <CenteredLoader />}
      </AuthenticationRequired>
    )
  }

  return children
}

export default AuthenticationFork
