import React from 'react'
import { Route } from 'react-router-dom'
import invariant from 'tiny-invariant'

import AuthenticationFork from '../components/AuthenticationFork'
import PageWrapper from '../containers/PageWrapper'
import { buildUrlPath } from '../util'
import config from './pagesConfig'

const getPages = () => config.map(({ subpages, ...page }) => {
  invariant(page.responsibleTeam, `Missing responsibleTeam for page: ${page.name}`)

  if (!subpages) {
    return {
      ...page,
      filePath: page.name,
      // @ts-ignore: FIXME
      urlPath: buildUrlPath(page.name, page.param),
      // @ts-ignore: FIXME
      overrideTopBarConfig: Boolean(page.overrideTopBarConfig)
    }
  }

  return [
    subpages.map(subpage => ({
      ...page,
      ...subpage,
      filePath: [page.name, subpage.name].join('/'),
      urlPath: buildUrlPath(page.name, subpage.name, subpage.param),
      parentName: page.name,
      overrideTopBarConfig: Boolean(subpage.overrideTopBarConfig)
    })),
    {
      ...page,
      filePath: page.name,
      // @ts-ignore: FIXME
      urlPath: buildUrlPath(page.name, page.param),
      // @ts-ignore: FIXME
      overrideTopBarConfig: Boolean(page.overrideTopBarConfig)
    }
  ]
})
  .flat(2)
  .map(({ requireAuthentication, filePath, experimentationFeatureKey, ...pageConfig }) => {
    const Page = React.lazy(
      () => import(/* webpackChunkName: "page_[request]" */ './' + filePath)
    )

    return (
      <Route path={pageConfig.urlPath} key={pageConfig.urlPath} exact>
        <AuthenticationFork requireAuthentication={requireAuthentication}>
          <PageWrapper {...pageConfig} experimentationFeatureKey={experimentationFeatureKey}>
            <Page />
          </PageWrapper>
        </AuthenticationFork>
      </Route>
    )
  })

export { buildUrlPath }

export default getPages
