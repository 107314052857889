"use strict";
const R = require('ramda');
const apStagingConfig = require('./ap-staging');
const devConfig = {
    common: {
        environment: {
            type: 'development'
        }
    }
};
module.exports = R.pipe(R.mergeDeepLeft(devConfig))(apStagingConfig);
