/* eslint-disable no-undef */
import React, { createContext, useContext, useState } from 'react'
import { getBotNode } from '../helpbotFlow'
import { useHelpContext } from '../../../hooks'
import {
  BotNode,
  BotNodeId,
  BotStep,
  HelpbotPath,
  BotEvent,
  UxEvent,
  HelpContext
} from '../types'
import { HELPBOT_SESSION_STORAGE_KEY } from '../../../constants'
import { storeDataIntoSession, getDataFromSessionStorage } from '../../../utilities/sessionStorage'

const HELPBOT_ROOT_NODE_ID: BotNodeId = BotNodeId.WHAT_HELP_WITH

export type HelpbotStateManager = {
  userPath: HelpbotPath,
  getBotStep: (nodeId: BotEvent) => BotStep,
  handleAnswerForActiveStep: (value: string) => void,
  getActiveNodeId: () => BotEvent,
  handleUxEvent: (value: BotEvent) => void
}

type HelpbotStateProviderProps = {
  children: React.JSX.Element
}

export const HelpbotState = createContext<HelpbotStateManager | undefined>(undefined)

export const HelpbotStateProvider = ({
  children
}: HelpbotStateProviderProps): React.JSX.Element | null => {
  // @ts-ignore
  const helpHook: HelpContext = useHelpContext()
  const [userPath, setUserPath] = useState<HelpbotPath>(getInitialState())

  const isLastStep = (pathIndex: number) => pathIndex === userPath.length - 1
  const lastStepNodeId = userPath[userPath.length - 1]
  const lastStepNode = getBotNode(lastStepNodeId)

  const getStepResponseValue = (botNode: BotNode, pathIndex: number): string | null => {
    if (isLastStep(pathIndex)) return null

    const pickedOption = botNode.options().find(o => o.next === userPath[pathIndex + 1])

    if (!pickedOption) throw Error('cannot get the user answer')

    return pickedOption.value
  }

  const getActiveNodeId = (): BotEvent => userPath[userPath.length - 1]

  const getBotStep = (nodeId: BotEvent): BotStep => {
    const pathIndex = userPath.indexOf(nodeId)
    if (pathIndex < 0) throw Error('cannot fetch bot step as not in user path')

    const botNode = getBotNode(nodeId)
    const responseValue = getStepResponseValue(botNode, pathIndex)

    return { node: botNode, responseValue }
  }

  const isUxEvent = (value: BotEvent): boolean => Object.keys(UxEvent).includes(value as string)

  const handleAnswerForActiveStep = (selectedValue: string) => {
    const pickedOption = getLatestPickedOption(selectedValue)
    if (!pickedOption) throw Error('cannot handle helpbot next step: the selected value is wrong')
    if (isUxEvent(pickedOption.next)) {
      handleUxEvent(pickedOption.next)
      return
    }
    handleNextNode(pickedOption.next)
  }

  const getLatestPickedOption = (selectedValue: string) =>
    lastStepNode.options().find(o => o.value === selectedValue)

  const handleNextNode = (nextNodeId) => {
    const newPath = [...userPath, nextNodeId]
    setUserPath(newPath)
    storeDataIntoSession(HELPBOT_SESSION_STORAGE_KEY, newPath)
  }

  const goToChannelSelection = () => helpHook.navigation.push('supportChannelSelector')

  const handleUxEvent = (uxEvent: BotEvent) => {
    switch (uxEvent) {
      case UxEvent.GO_TO_HELP_HOME:
        helpHook.navigation.reset()
        break
      case UxEvent.GO_TO_CHANNEL_SELECTION:
        setUserPath([...userPath, UxEvent.GO_TO_CHANNEL_SELECTION])
        if (helpHook.isChatSelectorInChatEnabled) return
        goToChannelSelection()
        break
      default:
        throw Error('cannot handle unknown UX event')
    }
  }

  const stateManager: HelpbotStateManager = {
    userPath,
    getBotStep,
    handleAnswerForActiveStep,
    handleUxEvent,
    getActiveNodeId
  }

  return (
    <HelpbotState.Provider value={stateManager}>{children}</HelpbotState.Provider>
  )
}

export const useHelpbotState = (): HelpbotStateManager => {
  const value = useContext(HelpbotState)

  if (!value) throw new Error('cannot get state manager as not been provided')

  return value
}

const getInitialState = (): HelpbotPath => {
  const storedState = getDataFromSessionStorage(HELPBOT_SESSION_STORAGE_KEY)
  // @ts-ignore
  if (storedState) return storedState
  return [HELPBOT_ROOT_NODE_ID]
}
