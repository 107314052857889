import { requestWrapper } from '../api/requestWrapper'
import { RegionalResponse, RequestMethodEnum, RegionEnum, CountryCodeAndFeatureData, CountryCodeAndRegion, Market, WorkingHour } from '../components/SupportHelpBot/types'
import { DAY_MS, HOUR_MS, MINUTE_MS } from '../constants'
import { memoizeAsync } from '../utilities/memoize'
import { getTransformedRestoreId, getTransformedWorkingHours } from './chat'
import { getCountryCodesAndRegions, getCountryCodes, getCountryCodesAndFeatureData, mergeAndGetMarkets } from './markets'

const getMarketsResponse = memoizeAsync(async (client: any): Promise<RegionalResponse[]> => requestWrapper(
  client,
  RequestMethodEnum.GET,
  'v1/markets'
), HOUR_MS)

const getChatExpFeature = memoizeAsync(async (client: any, markets: string[]): Promise<RegionalResponse[]> => requestWrapper(
  client,
  RequestMethodEnum.GET,
  'v1/chat/exp/feature',
  { markets },
  [RegionEnum.EU]
), MINUTE_MS * 5)

const getWorkingHoursResponse = memoizeAsync(async (client: any): Promise<RegionalResponse[]> => requestWrapper(
  client,
  RequestMethodEnum.GET,
  'v1/chat/working-hours'
), DAY_MS)

const getRestoreIdResponse = memoizeAsync(async (client: any): Promise<RegionalResponse[]> => requestWrapper(
  client,
  RequestMethodEnum.GET,
  'v1/chat/restore-id'
), DAY_MS)

export const getMarkets = async (client: any): Promise<Market[]> => {
  const marketsResponse: RegionalResponse[] = await getMarketsResponse(client)
  const countryCodesAndRegions: CountryCodeAndRegion[] = getCountryCodesAndRegions(marketsResponse)
  const countryCodes: string[] = getCountryCodes(countryCodesAndRegions)

  const chatExpFeatures: RegionalResponse[] = await getChatExpFeature(client, countryCodes)
  const countryCodesAndFeatureData: CountryCodeAndFeatureData[] = getCountryCodesAndFeatureData(chatExpFeatures)

  return mergeAndGetMarkets(countryCodesAndRegions, countryCodesAndFeatureData)
}

export const getRestoreId = async (client: any) => {
  const restoreIdResponse: RegionalResponse[] = await getRestoreIdResponse(client)
  return getTransformedRestoreId(restoreIdResponse)
}

export const getWorkingHours = async (client: any): Promise<WorkingHour[]> => {
  const workingHoursResponse: RegionalResponse[] = await getWorkingHoursResponse(client)
  return getTransformedWorkingHours(workingHoursResponse)
}
