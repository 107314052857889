import { Loader } from '@klarna/bubble-ui'
import React from 'react'

function CenteredLoader () {
  return (
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
    >
      <Loader />
    </div>
  )
}

export default CenteredLoader
