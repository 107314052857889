import { DesignTokens } from '@klarna/bubble-ui'
import { getFreshChatConfig } from './config'
import generateTag from './generateTag'
import { StartConversationContext } from './types'

const FRESHCHAT_APP_LOGO_URL = 'https://a.klarnacdn.net/klapp/assets/support-chat/klarna_logo.png'
const BACKGROUND_IMAGE_BASE_64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='

export const getFreschatWidgetOptions = (ctx: StartConversationContext): any => {
  const freshChatConfig = getFreshChatConfig(ctx.env, ctx.region)

  const font = ctx.designContext.get<DesignTokens.TextStyle>('text-style/headings/titles/medium/tertiary')

  return {
    token: freshChatConfig.token,
    host: freshChatConfig.host,
    tags: [generateTag(ctx)],
    externalId: ctx.tokenHelper.getEmail(),
    restoreId: getRestoreId(ctx),
    config: {
      agent: {
        hidePic: true,
        hideName: true,
        hideBio: true
      },
      headerProperty: {
        hideChatButton: true,
        appName: 'Klarna',
        appLogo: FRESHCHAT_APP_LOGO_URL,
        backgroundColor: ctx.designContext.get<DesignTokens.Color>('colors/backgrounds/inverse').toString(),
        foregroundColor: ctx.designContext.get<DesignTokens.Color>('colors/text/inverse').toString(),
        backgroundImage: BACKGROUND_IMAGE_BASE_64,
        fontName: font.fontFamily
      },
      content: {
        headers: {
          chat: ctx.t('conversation.widget.title'),
          chat_help: ' '
        }
      }
    }
  }
}

type GetWidgetUrlParams = Pick<StartConversationContext, 'env' | 'region'>

export const getFreshchatWidgetUrl = (ctx: GetWidgetUrlParams): any => {
  const freshChatConfig = getFreshChatConfig(ctx.env, ctx.region)
  return freshChatConfig.widget
}

const getRestoreId = ({ restoreIdByRegion, region }: StartConversationContext) => restoreIdByRegion?.[region!]
