import React, { createContext, useContext, useEffect, useState } from 'react'
import { useChatClient } from '../hooks/useChatClient'
import { ChannelData, Market, WorkingHour, RestoreId } from '../components/SupportHelpBot/types'
import { getMarkets, getRestoreId, getWorkingHours } from '../data/channelData'

export const ChannelDataContext = createContext<ChannelData | undefined>(undefined)

type ContactChannelStateProviderProps = {
  children: React.JSX.Element
}

export const ChannelDataStateProvider = ({
  children
}: ContactChannelStateProviderProps): React.JSX.Element => {
  const client = useChatClient()

  const marketsInitState: Market[] = []
  const workingHoursInitState: WorkingHour[] = []
  const restoreIdInitState: RestoreId = { eu: '', ap: '', us: '' }
  const state = {
    loading: true,
    markets: marketsInitState,
    workingHours: workingHoursInitState,
    restoreId: restoreIdInitState
  }
  const [currentState, setState] = useState(state)

  const loadData = async (controller: AbortController) => {
    setState(currentState => ({ ...currentState, loading: true }))

    // This hack is needed because the App.js component is rerendering the chat bubble multiple times
    // causing the effect to trigger multiple API requests.
    // Best solution would be to fix the App.js component renders, but that might take way more effort.
    // Once we remove all sidebar logic, we can extract the whole chat bubble feature out of the App.js
    // file, and then we can fix the rerendering issue.
    await new Promise(resolve => setTimeout(resolve, 200))
    if (controller.signal.aborted) return

    const [markets, workingHours, restoreId] = await Promise.all([getMarkets(client), getWorkingHours(client), getRestoreId(client)])
    setState({ markets, workingHours, restoreId, loading: false })
  }

  useEffect(() => {
    const controller = new AbortController()

    loadData(controller).catch(() => {})
    return () => {
      controller.abort()
    }
  }, [])

  const contextValue = {
    isLoading: currentState.loading,
    data: {
      markets: currentState.markets,
      workingHours: currentState.workingHours,
      restoreId: currentState.restoreId
    }
  }

  return (
    <ChannelDataContext.Provider value={contextValue}>{children}</ChannelDataContext.Provider>
  )
}

export const useChannelDataState = (): ChannelData => {
  const value = useContext(ChannelDataContext)
  if (!value) throw new Error('cannot get ChannelData state manager as it has not been provided')
  return value
}
