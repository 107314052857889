import { useFeature } from '@merchant-portal/experimentation'
import { usePageContext } from '@merchant-portal/framework'
import { hooks } from 'mage-i18n'
import { bool, node, string } from 'prop-types'
import React, { Suspense } from 'react'

import CenteredLoader from '../components/CenteredLoader'
import NotFound from './NotFound'

PageWrapper.propTypes = {
  name: string,
  children: node,
  parentName: string,
  overrideTopBarConfig: bool,
  urlPath: string,
  experimentationFeatureKey: string
}

function PageWrapper ({ name, children, parentName, overrideTopBarConfig, urlPath, experimentationFeatureKey }) {
  const t = hooks.useTranslator()
  const isEnabled = useFeature(experimentationFeatureKey) || !experimentationFeatureKey

  const { setPageContext = () => {} } = usePageContext()

  React.useEffect(() => {
    const title = t(`static-pages.${name}.pageTitle`) || t(`static-pages.${parentName}.pageTitle`)

    if (title) {
      if (!overrideTopBarConfig) {
        setPageContext({
          title
        })
      }
      document.title = `${title} | Klarna Merchant Portal`
    }
  }, [t, overrideTopBarConfig])

  return (
    <Suspense fallback={<CenteredLoader />}>
      <div data-testid={`page[${urlPath}]`}>
        {isEnabled ? children : <NotFound />}
      </div>
    </Suspense>
  )
}

export default PageWrapper
