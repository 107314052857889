"use strict";
const R = require('ramda');
const usStagingConfig = require('./us-staging');
const devConfig = {
    common: {
        environment: {
            type: 'development'
        }
    }
};
module.exports = R.pipe(R.mergeDeepLeft(devConfig))(usStagingConfig);
