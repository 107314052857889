import { StartConversationContext } from './types'

function generateTag (ctx: StartConversationContext): string {
  const { market } = ctx
  if (!market || typeof market !== 'string') {
    throw Error('A correct market is required to open a new chat window.')
  }

  if (ctx.tag) return ctx.tag

  return `merchantportal-${market.toLowerCase()}`
}

export default generateTag
