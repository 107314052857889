/* eslint-disable no-undef */
export enum BotNodeId {
  PAYOUTS,
  ORDERS,
  SETTLEMENTS,
  DISPUTES,

  // ORDERS - SUB-CATEGORIES
  ORDER_MANAGEMENT,
  ORDER_PROCESSING,
  ORDER_MODIFICATIONS_AND_ISSUES,
  ORDER_REFUND_ISSUES,
  ORDER_RESERVATIONS,
  ORDER_PAYMENTS,

  // ORDER_MANAGEMENT - QUESTIONS
  ORDER_MANAGEMENT_CREATE_ORDER_POS,
  ORDER_MANAGEMENT_EXTEND_EXPIRY_RESERVATION,

  ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS,
  ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_PARTIALLY,
  ORDER_MANAGEMENT_HOW_CAPTURE_ORDERS_FULLY,

  ORDER_MANAGEMENT_CAN_I_UPDATE_CUSTOMER_INFO,
  ORDER_MANAGEMENT_EXTEND_DUE_DATE,
  ORDER_MANAGEMENT_REFUND_ORDER,
  ORDER_MANAGEMENT_CANCEL_ORDER,
  ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS,
  ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DIRECT_PARTNER,
  ORDER_MANAGEMENT_UNABLE_TO_TAKE_ACTIONS_DISTRIBUTION_PARTNER,

  ORDER_REFUND_ISSUES_REFUNDED_WHEN_CUSTOMER_GET_FUNDS,
  ORDER_RESERVATIONS_UNABLE_TO_EXTEND_TIME,

  // ORDER_PAYMENTS - QUESTIONS
  ORDER_PAYMENTS_WHEN_WILL_I_GET_PAID,
  ORDER_PAYMENTS_REFUND_ORDER_RECEIVED_PAYMENT,
  ORDER_PAYMENTS_REFERENCE_NOT_SHOW_UP,
  ORDER_PAYMENTS_GOT_PAID_SPECIFIC_ORDER,

  // DISPUTES - SUB-CATEGORIES
  MERCHANT_DISPUTES_APP,
  DISPUTES_LIFECYCLE,
  DISPUTES_HANDLING,
  DISPUTES_MANAGEMENT,
  DISPUTES_CONTACT_INFO_NOTIFICATIONS,
  // DISPUTES - DISPUTES APP QUESTIONS
  DISPUTES_APP_RESPOND_TO_DISPUTE,
  DISPUTES_APP_OVERVIEW_DISPUTES_CUSTOMER,
  DISPUTES_APP_OVERVIEW_DISPUTES_RESPONSE,
  DISPUTES_APP_UPDATE_CONTACT_INFORMATION,
  DISPUTES_APP_UPDATE_CUSTOMER_SERVICE_ON_KLARNA_APP,
  DISPUTES_APP_IMPROVE_DISPUTES_PERFORMANCE,
  DISPUTES_APP_OVERVIEW_DISPUTES_PERFORMANCE,
  // DISPUTES - DISPUTES LIFECYCLE QUESTIONS
  DISPUTES_LIFECYCLE_TIMING_AND_DEADLINES,
  DISPUTES_LIFECYCLE_RESOLUTION_PROCESS,
  DISPUTES_LIFECYCLE_WHEN_CAN_SEE_DISPUTES,
  DISPUTES_LIFECYCLE_HOW_LONG_TO_RESOLVE,
  DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_REFUND,
  DISPUTES_LIFECYCLE_HOW_TO_RESOLVE,
  DISPUTES_LIFECYCLE_WHEN_TO_RESPOND,
  DISPUTES_LIFECYCLE_HOW_MUCH_TIME_TO_RESPOND,
  DISPUTES_LIFECYCLE_HOW_LONG_TO_SEE_DISPUTES,
  DISPUTES_LIFECYCLE_WHEN_TO_GET_CHARGED,
  // DISPUTES - DISPUTES HANDLING QUESTIONS
  DISPUTES_HANDLING_WHAT_IS_DISPUTE,
  DISPUTES_HANDLING_HOW_MANY_TIMES_DEFEND,
  DISPUTES_HANDLING_DO_I_RECEIVE_NOTIFICATION,
  DISPUTES_HANDLING_HOW_TO_AVOID_DISPUTES_FEES,

  SETTLEMENTS_PAYOUT_NOT_FOUND,
  SETTLEMENTS_PAYOUT_NOT_FOUND_ORDER_NUMBER_NOT_IN_REPORT,
  SETTLEMENTS_PAYOUT_NOT_FOUND_CHECK_IF_PAID_FOR_ORDER,

  SETTLEMENTS_PAYOUT,
  // SETTLEMENTS_PAYOUT_WHEN_IS_NEXT,
  SETTLEMENTS_PAYOUT_WHY_CALCULATION_NOT_MATCH,
  // SETTLEMENTS_PAYOUT_WHEN_GET_PAID,
  SETTLEMENTS_PAYOUT_MISSING_PAYOUT,
  SETTLEMENTS_PAYOUT_HOW_CALCULATED,

  SETTLEMENTS_HOW_PAYOUT_WORKS,

  SETTLEMENTS_PAYOUT_SCHEDULE,
  SETTLEMENTS_PAYOUT_SCHEDULE_WHY_DELAY,
  SETTLEMENTS_PAYOUT_SCHEDULE_CHANGE_PAYOUT_DELAY,

  SETTLEMENTS_GENERATING_REPORTS,
  SETTLEMENTS_GENERATING_REPORTS_SEE_REPORTS,
  SETTLEMENTS_GENERATING_REPORTS_CONVERT_CSV_TO_EXCEL,

  SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION,
  SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_FEES_CALCULATED,
  SETTLEMENTS_PAYOUT_AND_FEE_CALCULATION_HOW_PAYOUT_CALCULATED,

  WHAT_HELP_WITH = 'WHAT_HELP_WITH',
  SELECT_PAYOUT = 'SELECT_PAYOUT',
  SELECT_ORDERS = 'SELECT_ORDERS',
  THAT_HELPED = 'THAT_HELPED',
  SELECT_SUPPORT_CHANNEL = 'SELECT_SUPPORT_CHANNEL',
  PAYOUT_NOT_PAID_CAPTURED_ORDERS = 'PAYOUT_NOT_PAID_CAPTURED_ORDERS',
  PAYOUT_WHEN_WILL_I_GET_PAID = 'PAYOUT_WHEN_WILL_I_GET_PAID',
  PAYOUT_PAID_WRONG_AMOUNT_CAPTURED_ORDERS = 'PAYOUT_PAID_WRONG_AMOUNT_CAPTURED_ORDERS',
  PAYOUT_CHANGE_PAYMENT_FREQUENCY = 'PAYOUT_CHANGE_PAYMENT_FREQUENCY',
  PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_UNABLE = 'PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_UNABLE',
  PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_ABLE = 'PAYOUT_CHANGE_PAYMENT_FREQUENCY_IS_ABLE',
  PAYOUT_NOT_PAID_IS_SCHEDULE_FOLLOWED = 'PAYOUT_NOT_PAID_IS_SCHEDULE_FOLLOWED',
  PAYOUT_PAID_WRONG_AMOUNT_IS_SCHEDULE_FOLLOWED = 'PAYOUT_PAID_WRONG_AMOUNT_IS_SCHEDULE_FOLLOWED',
  PAYOUT_NO_CAPTURED_ORDERS = 'PAYOUT_NO_CAPTURED_ORDERS',
  PAYOUT_IS_HOLDBACK_OR_DEBT = 'PAYOUT_IS_HOLDBACK_OR_DEBT',
  PAYOUT_SCHEDULE_FOLLOWED_EXPLANATION = 'PAYOUT_SCHEDULE_FOLLOWED_EXPLANATION',
  PAYOUT_NO_HOLDBACK = 'PAYOUT_NO_HOLDBACK',
  PAYOUT_WITH_HOLDBACK = 'PAYOUT_WITH_HOLDBACK',
  PAYOUT_FEES_RETURNS_HOLDBACKS_EXPLANATION = 'PAYOUT_FEES_RETURNS_HOLDBACKS_EXPLANATION',
  PAYOUT_PAID_WRONG_AMOUNT_SCHEDULE_FOLLOWED_EXPLANATION = 'PAYOUT_PAID_WRONG_AMOUNT_SCHEDULE_FOLLOWED_EXPLANATION',
  PAYOUT_WHEN_WILL_I_GET_PAID_CAPTURED_ORDERS = 'PAYOUT_WHEN_WILL_I_GET_PAID_CAPTURED_ORDERS',
  PAYOUT_WHEN_WILL_I_GET_PAID_SCHEDULE_FOLLOWED = 'PAYOUT_WHEN_WILL_I_GET_PAID_SCHEDULE_FOLLOWED',
  ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER = 'ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER',
  ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_YES = 'ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_YES',
  ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_NO = 'ORDERS_DONT_KNOW_HOW_TO_CAPTURE_ORDER_NO',
  ORDERS_MISSED_CAPTURE_DEADLINE = 'ORDERS_MISSED_CAPTURE_DEADLINE',
  ORDERS_EXPIRATION_DATE_SURPASSED = 'ORDERS_EXPIRATION_DATE_SURPASSED',
  ORDERS_EXPIRATION_DATE_SURPASSED_CREDIT = 'ORDERS_EXPIRATION_DATE_SURPASSED_CREDIT',
  ORDERS_MISSED_CAPTURE_DEADLINE_NO = 'ORDERS_MISSED_CAPTURE_DEADLINE_NO',
  ORDERS_REFUND_AN_ORDER = 'ORDERS_REFUND_AN_ORDER',
  ORDERS_FULLY_CAPTURED_YES = 'ORDERS_FULLY_CAPTURED_YES',
  ORDERS_FULLY_CAPTURED_NO = 'ORDERS_FULLY_CAPTURED_NO',
  ORDERS_FULLY_CAPTURED_PARTIALLY = 'ORDERS_FULLY_CAPTURED_PARTIALLY',
}

export enum UxEvent {
  GO_TO_HELP_HOME = 'GO_TO_HELP_HOME',
  GO_TO_CHANNEL_SELECTION = 'GO_TO_CHANNEL_SELECTION'
}

export type BotEvent = BotNodeId | UxEvent

export type AnswerOption = {
    value: string,
    next: BotEvent,
}

export type BotNode = {
    id: BotEvent,
    botMessage: React.ElementType | null,
    OptionsComponent?: React.ElementType | null,
    options: () => AnswerOption[],
}

export type BotStep = {
    node: BotNode,
    responseValue: string | null
}

export type HelpbotFlow = BotNode[]

export type HelpbotPath = BotEvent[]

export interface HelpContext {
  navigation: {
    reset: () => void,
    push: (path: string, params?: Record<string, unknown>) => void
  }
  isChatSelectorInChatEnabled: boolean
}

export enum RequestMethodEnum {
  GET,
  POST,
}

export enum RegionEnum {
  EU = 'eu',
  US = 'us',
  AP = 'ap'
}

export type RegionalResponse = {
  region: RegionEnum,
  response: any
}

export type CountryCodeAndRegion = {
  countryCode: string,
  region: RegionEnum,
}

export type CountryCodeAndFeatureData = {
  countryCode: string,
  isChatFeatureEnabled: boolean
}

export type Market = {
  countryCode: string,
  region: RegionEnum,
  isChatFeatureEnabled: boolean
}

export type RestoreId = {
  eu: string,
  us: string,
  ap: string
}

export type WorkingHour = {
  marketCountryCodes: string[],
  timeFramesByWeekday: {
    start: number,
    end: number
  }[][],
  timezone: string
  region: RegionEnum
}

export type ChannelData = {
  isLoading: boolean,
  data: {
    markets: Market[],
    workingHours: WorkingHour[],
    restoreId: RestoreId
  }
}
