"use strict";
const R = require('ramda');
const euStagingConfig = require('./eu-staging');
const devConfig = {
    common: {
        environment: {
            type: 'development'
        }
    }
};
module.exports = R.pipe(R.mergeDeepLeft(devConfig))(euStagingConfig);
