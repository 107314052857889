"use strict";
module.exports = {
    "common": {
        "environment": {
            "region": "us"
        },
        "merchantSupport": {
            "default": "US"
        }
    }
};
